import { useParams } from 'react-router-dom';
import Event from '../components/Event';

const EventRoute = () => {
	const { eventId } = useParams();

	return <Event eventId={eventId!} />;
};

export default EventRoute;
