import { DocumentSnapshot, getDocs, loadBundle, namedQuery, Timestamp } from 'firebase/firestore';
import { firestore } from '../firebase';
import { ClubIdentifier } from '../domain/types';

const _convertDocToEventDetails = (snapshot: DocumentSnapshot): EventData => ({
	id: snapshot.id,
	name: snapshot.get('title'),
	description: snapshot.get('description'),
	startTimeMs: (snapshot.get('start') as Timestamp).toMillis(),
	imageUri: snapshot.get('imageUrl'),
	tags: snapshot.get('tags'),
	shareLink: snapshot.get('shareLink'),
	shareLinks: snapshot.get('shareLinks'),
});

export type EventData = {
	id: string;
	name: string;
	description: string;
	startTimeMs: number;
	imageUri: string;
	tags: string[];
	shareLink?: string;
	shareLinks?: Partial<Record<ClubIdentifier, string>>;
};

export const getEvents = async (clubId: ClubIdentifier) => {
	try {
		const url = `${process.env.REACT_APP_CONTENT_HOST}/content/loxclubevents?clubId=${clubId}`;
		const response = await fetch(url);
		const bundle = await response.text();

		await loadBundle(firestore, bundle);

		const query = await namedQuery(firestore, 'active-loxclubevents-query');
		if (query) {
			const snapshot = await getDocs(query);
			return snapshot.docs.map(_convertDocToEventDetails);
		}
	} catch (e) {
		console.log('failed to load events query', e);
	}
	console.warn('events query not found');
	return [];
};

export const getEvent = async (
	clubId: ClubIdentifier,
	eventId: string
): Promise<EventData | undefined> => {
	const events = await getEvents(clubId);
	return events.find((event) => event.id === eventId);
};
