import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const Header = () => {
	const { t } = useTranslation();

	return (
		<Row className="my-3">
			<Col>
				<a href={t('website_url')}>
					<div className="navigationLogo" />
				</a>
			</Col>
		</Row>
	);
};

export default Header;
