import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const Footer = () => {
	const { t } = useTranslation();
	return (
		<Row className="my-3">
			<Col>
				<div className="d-flex align-items-end justify-content-center footer">
					<div className="text-center">
						© {t('club_name')} {new Date().getFullYear()}
					</div>
				</div>
			</Col>
		</Row>
	);
};

export default Footer;
