import { FC, PropsWithChildren } from 'react';
import Header from './Header';
import { Col, Container, Row } from 'react-bootstrap';
import Footer from './Footer';

const Layout: FC<PropsWithChildren> = ({ children }) => {
	return (
		<Container>
			<Header />
			<Row className="my-3">
				<Col>{children}</Col>
			</Row>
			<Footer />
		</Container>
	);
};

export default Layout;
