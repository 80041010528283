import { FC } from 'react';
import { EventComponentParams } from './types';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import dateformat from 'dateformat';

const TagPill: FC<{ tag: string }> = ({ tag }) => <div className="eventTagPill">{tag}</div>;

const EventComponent: FC<EventComponentParams> = ({ eventData, loading }) => {
	const { t } = useTranslation();

	if (loading) {
		return (
			<Container>
				<Helmet>
					<title>{`${t('club_name')} - Event not found`}</title>
				</Helmet>
				<Row className="justify-content-center">
					<Col md={10} lg={6}>
						<Row>
							<Col className="text-center">Loading event...</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		);
	}

	if (!eventData) {
		return (
			<Container>
				<Helmet>
					<title>{`${t('club_name')} - Event not found`}</title>
				</Helmet>
				<Row className="justify-content-center">
					<Col md={10} lg={6}>
						<Row>
							<Col className="text-center">Event not found</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		);
	}

	return (
		<Container>
			<Helmet>
				<title>{`${t('club_name')} - ${eventData.name}`}</title>
			</Helmet>
			<Row className="justify-content-center">
				<Col md={10} lg={6}>
					<Row className="my-4">
						<Col className="text-center">
							<img className="eventSplash" src={eventData.imageUri} alt="event splash" />
						</Col>
					</Row>
					<Row className="my-4">
						<Col className="text-center">
							<div className="eventTagsContainer">
								{eventData.tags.map((tag) => (
									<TagPill key={tag} tag={tag} />
								))}
							</div>
						</Col>
					</Row>
					<Row>
						<Col className="eventTitle">{eventData.name}</Col>
					</Row>
					<Row>
						<Col>{dateformat(eventData.startTimeMs, 'ddd, mmm d, h:MM TT Z')}</Col>
					</Row>
					<Row className="my-4">
						<Col>
							<Row>
								<Col className="eventLabel">Event Details</Col>
							</Row>
							<Row>
								<Col className="eventLabelDescription">{eventData.description}</Col>
							</Row>
						</Col>
					</Row>

					<Row className="my-5">
						<Col sm={6} xs={8} className="mx-auto">
							<a
								className="eventPillButtonLink"
								href={`${t('signup_url')}${window.location.search}`}
							>
								<div className="eventPillButton">Apply now</div>
							</a>
						</Col>
					</Row>
				</Col>
			</Row>
		</Container>
	);
};

export default EventComponent;
