import React, { FC, PropsWithChildren } from 'react';
import { Theme } from '../domain/types';

const LoxClubTheme = React.lazy(() => import('./LoxClubTheme'));
const JadeClubTheme = React.lazy(() => import('./JadeClubTheme'));

const _resolveTheme = () => {
	const hostname = window.location.hostname;
	const loxClubRegExp = /^(?:.*\.)?loxclubapp\.com$/;
	const jadeClubRegExp = /^(?:.*\.)?jadeclubapp\.com$/;

	if (hostname.match(loxClubRegExp)) {
		return Theme.LOX_CLUB;
	}

	if (hostname.match(jadeClubRegExp)) {
		return Theme.JADE_CLUB;
	}

	return Theme.LOX_CLUB;
};

export const theme: Theme = _resolveTheme();

const ThemeSelector: FC<PropsWithChildren> = ({ children }) => {
	return (
		<React.Suspense fallback={<></>}>
			{(() => {
				switch (theme) {
					case Theme.LOX_CLUB: {
						return <LoxClubTheme />;
					}
					case Theme.JADE_CLUB: {
						return <JadeClubTheme />;
					}
					default: {
						return <LoxClubTheme />;
					}
				}
			})()}
			{children}
		</React.Suspense>
	);
};

export default ThemeSelector;
