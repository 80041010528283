import { initializeApp } from 'firebase/app';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectStorageEmulator, getStorage } from 'firebase/storage';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { getAnalytics} from 'firebase/analytics';

declare global {
	var FIREBASE_APPCHECK_DEBUG_TOKEN: boolean | string | undefined;
}

const firebaseConfig = {
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_DATABASE_URL,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_APP_ID,
	measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const appCheckKey = process.env.REACT_APP_APPCHECK_SITE_KEY;

if (process.env.REACT_APP_USE_EMULATORS) {
	initializeApp({
		...firebaseConfig,
		projectId: 'demo-test-project',
	});
	connectFirestoreEmulator(getFirestore(), 'localhost', 8080);
	connectAuthEmulator(getAuth(), 'http://localhost:9099');
	connectStorageEmulator(getStorage(), 'localhost', 9199);
} else {
	const app = initializeApp(firebaseConfig);
	if (appCheckKey) {
		window.self.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.REACT_APP_CHECK_DEBUG_KEY;
		initializeAppCheck(app, {
			provider: new ReCaptchaV3Provider(appCheckKey),
			isTokenAutoRefreshEnabled: true,
		});
	}
}

export const firestore = getFirestore();
export const analytics = getAnalytics()
